<template>
  <div>
    <card elevation="3" color="white" class="card-garage font-size-12 rounded-lg mini-card pa-0">
      <template #text>
        <v-icon class="coeur" :class="{favori: isFavori}" @click="initSettingFavori">
          {{ isFavori ? 'mdi-heart' : 'mdi-heart-outline' }}
        </v-icon>
        <ModaleGenerique ref="modale" :width="'350px'">
          <template #content>
            <div class="d-flex flex-column justify-center align-center mt-3">
              <v-icon color="var(--theme-icone)"> mdi-heart-outline </v-icon>

              <span style="width: 80%" class="text-center mt-2">
                Êtes-vous certain de vouloir changer votre garage favori ?
              </span>
              <div style="width: 100%" class="d-flex justify-space-around mt-3">
                <v-btn class="rounded-pill" outlined dark color="var(--theme-icone)" @click="closeModale()">
                  Annuler
                </v-btn>
                <v-btn class="rounded-pill" dark color="var(--theme-icone)" @click="setFavori()"> Valider </v-btn>
              </div>
            </div>
          </template>
        </ModaleGenerique>
        <v-row no-gutters class="d-flex">
          <v-col :class="{'mt-7': recommande}" class="align-center justify-center d-flex flex-column" cols="3">
            <span v-if="recommande" class="recommande font-size-10" mt-2>Recommandé</span>
            <v-img :src="fournisseur.urlLogo" height="70" width="70" alt="" class="mt-2" />
            <NotationGarage :is-note-sent="isNoteSent" :fournisseur="fournisseur" @init-notation="isNoteSent = false" />
            <v-tooltip
              v-if="fournisseur.ecoResponsable"
              location="top"
              color="var(--theme-icone)"
              content-class="rounded-lg px-2 py-1 font-size-10 mt-3 text-center"
            >
              <template #activator="{props}">
                <div v-bind="props" class="eco-responsable-container">
                  <icon-leaf filled style="font-size: 25px" />
                </div>
              </template>
              <span>Répond à nos critères <br />d'<b>éco-responsabilité</b></span>
            </v-tooltip>
          </v-col>
          <v-col :class="{'pt-6': recommande}" class="text-wrap pt-5 pl-2" cols="9">
            <div class="text-company font-size-12 text-capitalLetter">
              {{ fournisseur.nomCommercialUsuel }}
            </div>
            <div class="space text-capitalLetter">
              {{ fournisseur.adresse?.adresse1.toLowerCase() }} - {{ fournisseur.adresse?.codePostal.toLowerCase() }}
              {{ fournisseur.adresse?.ville.toLowerCase() }}
            </div>
            <div v-if="hasTelephone" class="space">
              <a class="contact-garage font-size-12" @click="call()">
                <b>
                  <v-icon color="var(--theme-lien)" dense>mdi-phone-in-talk-outline</v-icon>
                  {{ getTelephone }}
                </b>
              </a>
            </div>
            <div v-if="hasEmail" class="space text-wrap">
              <a :href="'mailto:' + getEmail" class="contact-garage font-size-12">
                <b>
                  <v-icon color="var(--theme-lien)" dense>mdi-email-outline</v-icon>
                  {{ getEmail }}
                </b>
              </a>
            </div>
            <div class="space text-wrap d-flex">
              <v-tooltip
                v-for="presta in prestationsVehiculeDisponibles"
                :key="presta.prestationCopilot"
                location="top"
                open-on-hover
                color="var(--theme-icone)"
                content-class="rounded-pill px-2 py-1 mr-1 mt-1 font-size-10"
              >
                <template #activator="{props}">
                  <div class="mr-1" v-bind="props">
                    <PrestationIcon
                      :prestation="presta.prestationCopilot.toString()"
                      class="presta__icon rounded-lg pa-1"
                      :is-selectionne="false"
                    />
                  </div>
                </template>

                <span>{{ $t(`recherche.prestation.${presta.prestationCopilot}`) }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="bottom-container mt-3">
          <div class="btn-container font-size-12">
            <div v-if="isEuromaster" class="btn" @click="goToPriseRDV()">
              <icon-calendar class="mr-1" filled style="font-size: 15px" />
              Prendre RDV
            </div>
            <div v-if="hasTelephone" class="btn" @click="call()">
              <icon-phone class="mr-1" filled style="font-size: 20px" />
              Appeler
            </div>
            <div class="btn" @click="goToMaps()">
              <icon-pin class="mr-1" style="font-size: 24px" />
              <div class="d-flex" :class="breakpoint ? 'flex-column' : ''">
                <span>Y aller&nbsp;</span>
                <span>{{ calculateDistance ? '(' + calculateDistance + ')' : '' }}</span>
              </div>
            </div>
          </div>
        </v-row>
      </template>
    </card>
  </div>
</template>

<script setup lang="ts">
import PrestationIcon from '~/components/trouver-prestataire/prestation-icone.vue';
import card from '~/components/ui/cards/card.vue';
import NotationGarage from '~/components/trouver-prestataire/notation-prestataire.vue';
import ModaleGenerique from '../ui/modale/modale-generique.vue';

const props = defineProps<{
  fournisseur: Fournisseur;
  immatriculation: string;
  recommande?: boolean;
}>();
const {name} = useDisplay();

const fournisseursStore = useFournisseursStore();
const adresseStore = useAdresseStore();
const vehiculeStore = useVehiculeStore();
const prestations = ref(vehiculeStore.getPrestationsCopilot());
const isNoteSent = ref(false);
const modale = ref(null);

const adresseSaisie = computed(() => {
  return `${adresseStore.getAdresse()}`;
});

const prestationsVehiculeDisponibles = computed((): PrestationsVehiculeCopilotEntry[] => {
  if (props.fournisseur.prestationsAutorisees) {
    return prestations.value.filter((prestation) =>
      props.fournisseur.prestationsAutorisees.includes(prestation.prestationCopilot.toString()),
    );
  }
  return [];
});

const breakpoint = computed(() => {
  return name.value === 'xs';
});

const isFavori = computed(() => {
  if (fournisseursStore.getFournisseurFavori) {
    return fournisseursStore.getFournisseurFavori?.id === props.fournisseur.id;
  }
  return false;
});
const hasTelephone = computed(() => {
  return Boolean(props.fournisseur.telephone);
});
const hasEmail = computed(() => {
  return Boolean(props.fournisseur.email);
});
const getEmail = computed(() => {
  return props.fournisseur.email || '';
});
const getTelephone = computed(() => {
  return props.fournisseur.telephone || '';
});
const isEuromaster = computed(() => {
  return String(props.fournisseur.nomReseau).toUpperCase() === 'EUROMASTER';
});

const calculateDistance = computed(() => {
  return distanceInKmBetweenEarthCoordinates(
    adresseStore.getLocalisation()?.latitude,
    adresseStore.getLocalisation()?.longitude,
    props.fournisseur.latitude,
    props.fournisseur.longitude,
  );
});

const valueOrEmpty = (value: string) => {
  return value || '';
};

const goToMaps = () => {
  if (
    /* Si on est sur iOS, ouvrir Apple Maps */
    /iphone|ipad|ipod/.test(String(navigator.platform).toLowerCase())
  ) {
    window.open(`https://maps.apple.com/?&saddr=${adresseSaisie.value}?&daddr=${encodedAdresse()}`);
  } else {
    window.open(`https://maps.google.com/?&saddr=${adresseSaisie.value}?&daddr=${encodedAdresse()}`);
  }
};

const encodedAdresse = () => {
  const {adresse1, adresse2, adresse3, codePostal, ville, boitePostale} = props.fournisseur.adresse;
  let adresseDestination = `${valueOrEmpty(adresse1)} ${valueOrEmpty(adresse2)}
        ${valueOrEmpty(adresse3)} ${valueOrEmpty(codePostal)} ${valueOrEmpty(ville)}
        ${valueOrEmpty(boitePostale)}`;
  adresseDestination = adresseDestination.replace(/\s+/g, ' '); // Supprimer espace en double
  return encodeURI(adresseDestination);
};

const call = () => {
  window.location.href = 'tel:' + getTelephone.value;
};

const goToPriseRDV = () => {
  window.open('https://ebooking.euromaster.com/fr_FR/B2B_VL/carPlate');
};

const initSettingFavori = () => {
  if (fournisseursStore.getFournisseurFavori && props.fournisseur.id !== fournisseursStore.getFournisseurFavori?.id) {
    modale.value.openModale();
  } else {
    setFavori();
  }
};

const setFavori = () => {
  fournisseursStore.ajouterFournisseurFavori(props.fournisseur.id);
  closeModale();
};

const closeModale = () => {
  modale.value.closeModale();
};
</script>

<style scoped>
.card-garage {
  color: black !important;
}

.card-garage:deep(.v-card) {
  overflow: hidden;
}

.card-garage:deep(.v-card-text) {
  padding: 0 !important;
}

.v-btn .v-btn--content {
  text-transform: unset !important;
}

.recommande {
  background-color: #ff6464;
  position: absolute;
  font-weight: 700;
  color: white;
  padding: 2px 5px 2px 8px;
  margin-top: 7px;
  top: 0;
  left: 0;
}

.mini-card {
  padding: 0 12px !important;
  margin-top: 2vh;
  width: 100%;
}

.card-garage:deep(.v-card__text) {
  padding: 8px !important;
  line-height: 1.5;
}

.text-company {
  font-weight: bold;
  color: black;
  width: 70%;
  text-transform: uppercase;
}

.contact-garage {
  color: var(--theme-lien);
  overflow: hidden;
}

.contact-garage b {
  display: flex;
  word-break: break-all;
  word-wrap: break-word;
  align-items: center;
}

.contact-garage b .v-icon {
  margin-right: 10px;
}

.btn-container {
  flex-wrap: nowrap;
  width: 100%;
  grid-column-gap: 1px;
  display: flex;
  background-color: none !important;
}

.btn {
  transition: background-color 0.5s ease;
  background-color: var(--theme-identite);
  color: white;
  display: flex;
  text-align: center;
  height: 35px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn-container .btn:hover {
  cursor: pointer;
  background-color: var(--theme-identite-2);
}

.bottom-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: column;
}

.eco-responsable-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 8px;
}

.eco-responsable-container:hover {
  cursor: pointer;
}

.btn-container > button,
.btn-container > a {
  font-size: 12px;
  flex-shrink: 1;
  margin-left: 4px;
  margin-right: 4px;
}

.v-rating .v-icon {
  padding: 3px !important;
}

.presta__icon:deep(svg) {
  width: 20px;
  height: 20px;
  fill: var(--theme-icone);
}

.presta__icon {
  transition: background-color 0.3s ease;
}

.presta__icon:hover {
  cursor: pointer;
  background-color: var(--grey600);
}

.coeur {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}

.favori {
  color: var(--theme-identite);
}

.confirmer_favori:deep(.icon) {
  stroke: var(--theme-icone);
  top: 10px;
  right: 10px;
  height: 40px;
  position: absolute;
}

.confirmer_favori:deep(.icon):hover {
  cursor: pointer;
}
</style>
<style>
.v-tooltip__content {
  line-height: 1;
}
</style>
